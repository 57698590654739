.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin: -100px 0 0 -100px;
}

/* Create three equal columns that float next to each other */
.column {
  float: left;
  width: 250px;
  height: 250px;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  display: grid;
  column-gap: 10px;
  list-style-type: none;
  white-space: normal;
}

.columnOne {
  display: flex;
  width: 300px;
  flex-wrap: wrap;
  justify-content: center;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
  white-space: normal;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other on smaller screens (600px wide or less) */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
