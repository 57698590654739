.carousel {
  overflow: hidden;
  text-align: center;
  display: inline-block;
  width: 300%;
  max-width: 1700px;
  padding: 16px;
  box-sizing: border-box;
}

.inner {
  white-space: nowrap;
  transition: transform 1s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  max-height: 200px;
  max-width: 200;
  color: #fff;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: #366ddd;
  color: #fff;
}

.image img {
  width: 100%;
  height: 2%;
  object-fit: cover;
  height: auto;
  max-height: 250px;
  width: auto;
  max-width: 250px;
}
