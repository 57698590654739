.item {
  text-align: center;
  display: inline-block;
  width: 400%;
  max-width: 1700px;
  padding: 16px;
  box-sizing: border-box;
}

.image {
  width: 100%;
  height: 15rem;
  overflow: hidden;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.image img {
  width: 100%;
  height: 2%;
  object-fit: cover;
  height: auto;
  max-height: 400px;
  max-width: 400px;
}

.content {
  text-align: center;
  padding: 1rem;
}

.content h3 {
  font-size: 1.25rem;
  color: #2c292b;
  margin: 1;
}

.actions {
  padding: 1rem;
  text-align: center;
}

.actions button {
  font: inherit;
  cursor: pointer;
  color: #366ddd;
  border: 1px solid #366ddd;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
}

.actions button:hover,
.actions button:active {
  background-color: #ffe2ff;
  color: red;
  border: 1px solid red;
}
